import React from "react"
import styled from "styled-components"
import * as Tailor from "tailor"
import apLogo from "ap-interactive-assets/images/AP_LOGO_38x44.png"

const Article = styled(Tailor.Article)`
  min-height: calc(100vh - 160px);
  padding-top: 60px;
`

const Layout = styled(Tailor.Section)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 3em;
  align-items: center;
  padding-bottom: 40px;

  @media only screen and (max-width: 1200px) {
    grid-template-columns: 1fr;
    grid-gap: 1em;
  }
`

const apRed = "#ff322e"
const Card = styled.div`
  position: relative;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
  width: 100%;
  height: 400px;
  margin-bottom: 0.6em;
  background: linear-gradient(#787878, #ececec);

  &:hover {
    background: linear-gradient(${apRed}, #ececec);
  }

  @media only screen and (max-width: 900px) {
    height: 350px;
  }

  @media only screen and (max-width: 600px) {
    height: 250px;
  }
`

const Text = styled.div`
  ${Tailor.utils.fontSansCond}
  font-weight: bold;
  font-size: 2em;
  position: absolute;
  left: 40px;
  bottom: 30px;

  &:after {
    display: block;
    content: " ";
    padding-top: 10px;
    width: 54px;
    border-bottom: 6px solid ${apRed};
  }
`

const Link = styled.a`
  text-decoration: none;
  color: inherit;

  &:hover {
    text-decoration: inherit;
    color: inherit;
  }
`

function Error404() {
  return (
    <div>
      <Tailor.Nav img={apLogo} href="https://apnews.com" dark />
      <Article>
        <Tailor.Headline
          headline="Page not found"
          dek="We're sorry, but this page doesn't seem to exist."
          center
        />
        <Layout wider>
          <Link href="/">
            <Card>
              <Text>Explore AP Projects</Text>
            </Card>
          </Link>
          <Link href="https://apnews.com">
            <Card>
              <Text>Read more on APNews</Text>
            </Card>
          </Link>
        </Layout>
      </Article>
      <Tailor.Footer dark />
    </div>
  )
}

Error404.propTypes = {}

Error404.defaultProps = {}

export default Error404
