import { render } from "ap-interactives-plumbing"
import Component from "js/components/error404"
import "scss/index.scss"

if (!window.__HTML_WEBPACK_PRERENDER_PLUGIN__) {
  const selector =
    'div.ap-interactive[data-interactive="projects-apnews"][data-entrypoint="404"]'
  const nodes = document.querySelectorAll(selector)
  nodes.forEach((node) => render.renderOrHydrate(Component, node))
}

export default render.ssr(Component)
